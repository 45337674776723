@font-face {
  font-family: main;
  src: url(assets/Montserrat-Regular.ttf);
}

p {
  font-family: main, sans-serif;
}

.fa-gap {
  margin-right: 20px;
}

@keyframes blink1 { 0%{ background:black; } 80%{ background:black; } 100%{ background:red; } }
@keyframes blink2 { 0%{ background:black; } 40%{ background:red; } 60%{ background:black; } }
@keyframes blink3 { 0%{ background:black; } 10%{ background:green; } 30%{ background:black; } 40%{ background:green; } 50%{ background:black; } }
@keyframes blink4 { 0%{ background:black; } 10%{ background:green; } 30%{ background:black; } 40%{ background:green; } 50%{ background:black; } 
60%{ background:green; } 65%{ background:black; } 75%{ background:green; } 85%{ background:black; } 95%{ background:green; } }
@keyframes blink5 { 5%{ background:black; } 7%{ background:green; } 15%{ background:black; } 30%{ background:green; } 35%{ background:black; } 
55%{ background:green; } 65%{ background:black; } 75%{ background:green; } 80%{ background:black; } 90%{ background:green; } }
@keyframes blink6 { 10%{ background:black; } 20%{ background:green; } 60%{ background:black; } 65%{ background:green; } 70%{ background:black; } 
75%{ background:green; } 85%{ background:black; } 90%{ background:green; } 95%{ background:black; } 99%{ background:green; } }

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}

h2 {
  font-family: main, sans-serif;
  color: #ffffff !important;
}

h4 {
  font-family: main, sans-serif;
  color: #ffffff !important;
  width: 100%;
  text-align: center;
}

h3 {
  font-family: main, sans-serif;
  color: #ffffff !important;
  font-size: 1.1em !important;
}

h5 {
  font-family: main, sans-serif;
  color: #FFFFFF !important;
  width: 100%;
  text-align: center;

  font-size: 0.8em !important;
  margin-top: 60px !important;
}

button {
  font-family: main, sans-serif;
}

a {
  font-family: main, sans-serif;
}

.product-divider {
  height: 2px;
  background: #BBBBBB;
  margin-left:25%;
  width: 50%;
  margin-bottom: 50px;
}

.title-logo {
  height: 35px;
  margin-left: 20px;
  margin-right: 20px;
}

.title-content {
  position: relative;
  margin: 20px;
  border-radius: 10px;
  padding: 20px;
  z-index: 100;
  text-align: center;
  font-size: 2.5em;
}

.intro-background {
  position: fixed;
  top: 0px;
  background-image: url(assets/datacenter.jpg);
  background-size: 1800px ;
  height: 100vh;
  width: 100%;
  
}

.intro-shield {
  position: fixed;
  top: 0px;
  background-size: cover;
  background-color: #00000099;
  height: 100vh;
  width: 100%;
}

.product-background {
  position: fixed;
  top: 0px;
  background-image: url(assets/fly.jpg);
  background-size: cover;
  height: 100vh;
  width: 100%;
}

.services-background {
  position: fixed;
  top: 0px;
  background-image: url(assets/tube2.jpg);
  background-size: cover;
  height: 100vh;
  width: 100%;
}

.ventures-background {
  position: fixed;
  top: 0px;
  background-image: url(assets/fernbud.jpg);
  background-size: cover;
  height: 100vh;
  width: 100%;
}

.sign-up {
  background-color: #000000 !important;
  color: #FFFFFF !important;
  border: 0px solid #000000 !important;
}

.navbar {
  background-color: #000000 !important;
  width: 100%;
}

.navbar-item {
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  transition: border-bottom 300ms 0ms, background-color 300ms 0ms;

  /* font-family: brandon-grotesque-black, sans-serif; */
  font-size: 0.5em;
  color: #FFFFFF !important;
  background: #000000;
  
  border-bottom: 0px solid #FFF;
  height: 60px;
}

.navbar-item:hover {
  background-color: #000000 !important;
  color: #FFFFFF !important;

  border-bottom: 5px solid #FFFFFF;
}

.navbar-link:hover {
  background-color: #000000 !important;
  color: #FFFFFF !important;
}

.navbar-live {
  background-color: #000000;
  border-bottom: 10px solid #FFFFFF;
}

.section-title {
  font-size: 2.5em;
}

.partner-cell {
  border-radius: 15px;
  border: 3px solid #FF0022;
  display: inline-block;
  width: calc(100% - 40px);
  min-height: 300px;
  padding: 30px;
  margin: 20px;
}

.partner-cell-logo {
  float: right;
  padding: 20px;
}

.partner-cell-image {
  height: 200px;
}

.product-show {
  width: 100%;
}

.product-universe {
  float: left;
}

.product-avarice {
  float: right;
}

.product-logo {
  width: 300px;
  /*float: right;*/
  /*border: 2px solid #444444; */
  border-radius: 5px;
  padding: 20px;
  /*margin-left: 20px;*/
  margin-bottom: 20px;
  background: #000000;
}

.product-image {
  height: 300px;
  /*float: right;*/
  /*border: 2px solid #444444; */
  padding: 20px;
  /*margin-left: 20px;*/
  margin-bottom: 20px;
  background: #000000;
}

.product-image-larger {
  height: 500px;
  /*float: right;*/
  /*border: 2px solid #444444; */
  padding: 20px;
  /*margin-left: 20px;*/
  margin-bottom: 20px;
  background: #000000;
}

.product-logo-1 {
  width: 300px;
  /*float: right;*/
  /*border: 2px solid #444444; */
  /*margin-left: 20px;*/
  margin-bottom: 20px;
}

pre {
  color: #FF0022 !important;
}

body {
  background-image: #000000; /*linear-gradient(to bottom right, #191919, #212121);*/
}

.main {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  color: white;
  background-color: #00000000;
}

.small_features_div {
  float: right;
  width: 250px;
}

.services-back {
 
  width: 100%;
  
  background-image: url(assets/tube1.jpg);
}

.content {
  position: relative;
  margin-top: 50px;
  margin-left: 50px;
  margin-right: 50px;
  
  color: #ffffff;
  border-radius: 6px;
  padding: 10px;
  z-index: 100;
}

.content-contact {
  position: relative;
  
  color: #ffffff;
  border-radius: 6px;
  padding-top: 50px;
  padding-left: 60px;
  padding-right: 60px;
  
  z-index: 100;
  
  width: 100%;
  height: calc(100vh - 59px);
  
  background-image: url(assets/large-logo.jpg);
  background-size: cover;
  
}

.sizer {
  z-index: -10;
  position: fixed;
  width: 100vw;
  height: calc(100vh - 59px);
}

.content-contact h2 {
  font-size: 1.75em;
}

.service-cycle {
  border-radius: 15px;
}

.content p {
  color: #bbbbbb;
  font-size: 0.7em;
}

.content-partners {
  position: relative;
  margin: 50px;
  background-color: #000000DD;
  border-radius: 10px;
  padding: 20px;
  z-index: 100;
  
}

.partners-container {
  
}

.features-ul li {
  padding-bottom: 30px;
}

.contact-ul li {
  padding-bottom: 30px;
}

.major-features-ol li {
  padding-bottom: 30px;
}

.background {
  height: 100%;
  z-index: 1;
}  

.desktop-screenshot {
  width: 100%;
  text-align: center;
  padding: 20px;
}

.screenshot-title {
  padding: 10px;
  border-radius: 5px;
  border: 4px solid #FF0022;
  color: #00DDFF;
}

.screenshots-panel {
  overflow-x: scroll;
}

.screenshots-panel::-webkit-scrollbar {
  display: none;
}

.screenshots-panel {
  -ms-overflow-style: none;
}

.screenshots-table {
  background-color: #00000000 !important;
  padding: 40px;
  margin-top: 50px;
  table-layout: fixed;
}

.screenshots-td {
  width: 350px;
}

.screenshots-expand-div {
  color: #00DDFF;
  float: right;
}

.screenshots-gap-div {
  height: 50px;
}

.partners-map {
  position: absolute;
  width: 100%;
  background-color: #00000000;
}

.screenshots {
}

.screenshots-table tr:hover {
  background-color: #00000000 !important;
}

.screenshots-table td {
  padding: 20px;
  text-align: center;
  
}

.content-paragraph {
  font-size: 0.8em;
  /* font-family: brandon-grotesque-black, sans-serif; */
}

.floating-phone {
  position: absolute;
}

.floating-target {
  position: absolute;
}

.floating-money {
  position: absolute;
}

.navbar {
  z-index: 1000;
}

.strong {
  color: #FFFFFF;
  /*text-shadow: 0 0 3px #FFAAAA, 0 0 6px #664444;
  font-size: 1.2em;*/
}

.stacked-map-1 {
  position: absolute;
  left: 0px;
  right: 0px;
  z-index: 5;
}

.stacked-map-2 {
  position: absolute;
  left: 0px;
  right: 0px;
  z-index: 10;
}


.feature-line {
  color: #FF0022;
}

.place-line {
  color: #FF0022;
}

.map-image {
  border-bottom: 1px solid #FFFFFF;
}

.legal {
  padding: 20px;
  font-size: 0.6em;
  background-color: #000000;
  z-index: 100;
}

.legal-image {
  float: right;
  width: 130px;
}

.partners-space {
  min-height: 100vh;
}

.contact-space {
  min-height: 100vh;
}

.animated-slow-appear {
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  transition: margin-left 300ms 0ms, opacity 300ms 0ms;
  
  overflow: hidden;
  margin-left: 100vw;
  opacity: 0;
}

.animated-slow-appear.show {
  opacity: 1;
  margin-left: 0vw;
}

.intro {
  z-index: 0;
  position: relative;
}

.features-ul {
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  transition: margin-left 300ms 0ms, opacity 300ms 0ms;
  
  overflow: hidden;
  height: 0px;
  opacity: 0;
}

.features-ul.show {
  height: auto;
  opacity: 1;
}

.features-expand-div {
  color: #FF0022;
  margin-left: auto;
}

.major-features-li {
  border-bottom: 1px solid #FFFFFF;
  margin-bottom: 20px;
}

a:link {
  color: white; 
  background-color: transparent; 
  text-decoration: none;
}

a:visited {
  color: white;
  background-color: transparent;
  text-decoration: none;
}

a:hover {
  color: #DDDDDD;
  background-color: transparent;
  text-decoration: none;
}

a:active {
  color: #DDDDDD;
  background-color: transparent;
  text-decoration: none;
}

@media screen and (max-width: 1200px) {
  a.no-lander {
    display: none;
  }
}

.logo-image {
  float: left;
  width: 160px;
  border-radius: 10px;
  border: 3px solid #FFFFFF;
  margin: 10px;
  margin-right: 40px;
}

.logo-image-nb {
  float: left;
  width: 200px;
  border-radius: 10px;
  border: 0px solid #00000000;
  margin: 10px;
  margin-right: 40px;
  padding-top: 10px;
  padding-bottom: 10px;
  background: #FFFFFF;
}

.logo-bit {
  min-height: 150px;
}

.logo-blerb {
  padding-left: 20px;
}


/* For lander */
.lander-background {
  position: relative;
  width: 100%;
  height: calc(100vh - 79px);
  background-color: #000000;
}

.lander {
  position: relative;
  width: 30px;
  height: 30px;
  border: 4px solid #00000000;
  background: #EEEEAA;
  
  box-shadow: 2px 2px 6px 2px rgba(150,150,100,0.5);

  border-radius: 10px 10px 0px 0px;
}

.lander-left-leg {
  position: absolute;
  left: -10px;
  width: 10px;
  height: 10px;
  bottom: 0px;
  background: #EEEEAA;
}

.lander-right-leg {
  position: absolute;
  right: -10px;
  width: 10px;
  height: 10px;
  bottom: 0px;
  background: #EEEEAA;
}

.lander-head {
  position: absolute;
  left: 5px;
  width: 10px;
  height: 20px;
  top: -20px;
  background: #EEEEAA;
  border-radius: 5px 5px 0px 0px;
}

.lander-flame {
  position: absolute;
  left: 0px;
  width: 20px;
  height: 20px;
  bottom: -20px;
  background: #FF0000;
  border-radius: 0px 0px 10px 10px;
}

.up-thrust {
  border-bottom: 4px solid #FF0000;
}

.left-thrust {
  border-right: 4px solid #FF0000;
}

.right-thrust {
  border-left: 4px solid #FF0000;
}

.moon {
  background-color: #FFFFFF;
  position: absolute;
  
  border-radius: 10px 10px 0px 0px;
}

.star {
  position: absolute;
  width: 3px;
  height: 3px;
  background: #FFFFFF;
}

.instructions {
  position: absolute;
  right: 10px;
  top: 10px;
  
  font-size: 0.7em;
  text-align: right;
}

.win-state {
  position: absolute;
  left: 10px;
  top: 10px;
  
  text-align: left;
}

.privacy-div {
  color: #ffffff;
  font-size: 0.7em;
  float: right;
  z-index: 100;
  position: relative;
  padding: 5px;
  border: 1px solid #ccccccaa;
  border-radius: 5px;
}



